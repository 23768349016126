import React from "react";
import {Row } from "react-bootstrap";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
     

   </Row>
        













        
      
    
  );
}

export default Techstack;
